<template>
  <div class="border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between">
    <h3 class="text-lg font-medium leading-6 text-gray-900">Warehouses</h3>
    <div class="flex">
      <div class="mt-3 sm:mt-0 sm:ml-4 sm:mr-4">
        <label for="search_candidate" class="sr-only">Search</label>
        <div class="flex rounded-md shadow-sm">
          <div class="relative flex-grow focus-within:z-10">
            <div
              class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
            >
              <SearchIcon class="h-5 w-5 text-gray-400" />
            </div>
            <input
              type="text"
              v-model="search"
              name="search_candidate"
              id="search_candidate"
              class="search block w-full rounded-none rounded-l-md border-gray-300 pl-10 focus:border-indigo-500 sm:hidden 2xl:focus:ring-indigo-500"
              placeholder="Search"
            />
            <input
              type="text"
              name="search_candidate"
              id="search_candidate"
              class="search hidden w-full rounded-none rounded-l-md border-gray-300 pl-10 focus:border-indigo-500 focus:ring-indigo-500 sm:block sm:text-sm"
              placeholder="Search"
            />
          </div>
          <Menu>
            <div class="relative">
              <MenuButton
                type="button"
                class="focus:outline-none relative -ml-px inline-flex items-center rounded-r-md border border-gray-300 bg-gray-50 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
              >
                <SortAscendingIcon class="h-5 w-5 text-gray-400" />
                <span class="ml-2">Sort</span>
                <ChevronDownIcon class="ml-2.5 -mr-1.5 h-5 w-5 text-gray-400" />
              </MenuButton>
              <transition
                enter-active-class="transition duration-100 ease-out"
                enter-from-class="transform scale-95 opacity-0"
                enter-to-class="transform scale-100 opacity-100"
                leave-active-class="transition duration-75 ease-out"
                leave-from-class="transform scale-100 opacity-100"
                leave-to-class="transform scale-95 opacity-0"
              >
                <MenuItems
                  class="focus:outline-none absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white text-left shadow-lg ring-1 ring-black ring-opacity-5"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="sort-menu"
                >
                  <div class="py-1" role="none">
                    <MenuItem>
                      <a
                        href="#"
                        class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                        role="menuitem"
                        >Name</a
                      >
                    </MenuItem>
                    <MenuItem>
                      <a
                        href="#"
                        class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                        role="menuitem"
                        >Date modified</a
                      >
                    </MenuItem>
                    <MenuItem>
                      <a
                        href="#"
                        class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                        role="menuitem"
                        >Date created</a
                      >
                    </MenuItem>
                  </div>
                </MenuItems>
              </transition>
            </div>
          </Menu>
        </div>
      </div>
      <button
        type="button"
        @click="openCreatePanel"
        class="order-0 focus:outline-none inline-flex items-center rounded-md border border-transparent bg-purple-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-purple-700 focus:ring-purple-500 sm:order-1 sm:ml-3"
      >
        Create
      </button>
    </div>
  </div>
  <div class="flex flex-col">
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
        <div
          class="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg"
        >
          <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  Name
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  Address
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  State
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  # Shelves
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  # Shelf Bins
                </th>
                <th scope="col" class="relative px-6 py-3">
                  <span class="sr-only">Edit</span>
                </th>
              </tr>
            </thead>
            <tbody
              class="divide-y divide-gray-200 bg-white"
              v-if="warehouses.length > 0"
            >
              <tr v-for="warehouse in warehouses" :key="warehouse.Id">
                <td class="whitespace-nowrap px-6 py-4 text-left">
                  <div class="flex items-center">
                    <div class="ml-4">
                      <div class="text-sm font-medium text-gray-900">
                        {{ warehouse.Name }}
                      </div>
                      <div class="text-sm text-gray-500">
                        {{ warehouse.Description }}
                      </div>
                    </div>
                  </div>
                </td>
                <td class="whitespace-nowrap px-6 py-4 text-left">
                  <div class="text-sm text-gray-900">
                    {{ warehouse.AddressLine1 }}
                  </div>
                  <div class="text-sm text-gray-500">
                    {{ warehouse.City }} | {{ warehouse.ZipCode }}
                  </div>
                </td>
                <td class="whitespace-nowrap px-6 py-4 text-left">
                  <div class="text-sm text-gray-900">
                    {{ warehouse.Country }} | {{ warehouse.State }}
                  </div>
                </td>
                <td class="whitespace-nowrap px-6 py-4 text-left">
                  <div class="text-sm text-gray-900">
                    {{ warehouse.Shelves.length }}
                  </div>
                </td>
                <td class="whitespace-nowrap px-6 py-4 text-left">
                  <div class="text-sm text-gray-900"></div>
                </td>
                <td
                  class="flex space-x-3 whitespace-nowrap px-6 py-4 text-right text-sm font-medium"
                >
                  <button
                    type="button"
                    v-tippy="{
                      content: 'Edit',
                      animation: 'scale',
                      animateFill: false,
                      theme: 'tomato',
                    }"
                    @click="handleEdit(user.Id)"
                    class="focus:outline-none inline-flex items-center rounded-md border border-transparent px-3 py-2 text-sm font-medium leading-4 text-purple-400 shadow-sm hover:text-purple-800 focus:ring-purple-500"
                  >
                    <PencilIcon class="h-5 w-5 flex-shrink-0 text-gray-400" />
                  </button>
                  <button
                    type="button"
                    v-tippy="{
                      content: 'Delete',
                      animation: 'scale',
                      animateFill: false,
                      theme: 'tomato',
                    }"
                    @click="handleDelete(user.Id)"
                    class="focus:outline-none inline-flex items-center rounded-md border border-transparent px-3 py-2 text-sm font-medium leading-4 text-red-400 shadow-sm hover:text-red-800 focus:ring-red-500"
                  >
                    <TrashIcon class="h-5 w-5 flex-shrink-0 text-red-400" />
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <pagination :pagination="pagination" @changePage="onChangePage" />
        </div>
      </div>
    </div>
  </div>

  <TransitionRoot as="template" :show="open">
    <Dialog
      as="div"
      static
      class="fixed inset-0 z-10 overflow-hidden"
      @close="open = false"
      :open="open"
    >
      <div class="absolute inset-0 overflow-hidden" v-loading="loading">
        <DialogOverlay class="absolute inset-0" />

        <div class="fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
          <TransitionChild
            as="template"
            enter="transform transition ease-in-out duration-500 sm:duration-700"
            enter-from="translate-x-full"
            enter-to="translate-x-0"
            leave="transform transition ease-in-out duration-500 sm:duration-700"
            leave-from="translate-x-0"
            leave-to="translate-x-full"
          >
            <div class="w-screen max-w-2xl">
              <form
                class="flex h-full flex-col overflow-y-scroll bg-white shadow-xl"
              >
                <div class="flex-1">
                  <!-- Header -->
                  <div class="bg-gray-50 px-4 py-6 sm:px-6">
                    <div class="flex items-start justify-between space-x-3">
                      <div class="space-y-1">
                        <DialogTitle class="text-lg font-medium text-gray-900">
                          New Warehouse
                        </DialogTitle>
                        <p class="text-sm text-gray-500">
                          Get started by filling in the information below to
                          create your new warehouse.
                        </p>
                      </div>
                      <div class="flex h-7 items-center">
                        <button
                          type="button"
                          class="focus:outline-none rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500"
                          @click="open = false"
                        >
                          <span class="sr-only">Close panel</span>
                          <XIcon class="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>

                  <!-- Divider container -->
                  <div
                    class="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0"
                  >
                    <!-- Warehouse name -->
                    <div
                      class="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5"
                    >
                      <div>
                        <label
                          for="warehouse_name"
                          class="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                        >
                          Warehouse name
                        </label>
                      </div>
                      <div class="sm:col-span-2">
                        <input
                          type="text"
                          v-model="create.name"
                          name="warehouse_name"
                          id="warehouse_name"
                          class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                    </div>

                    <!-- Warehouse description -->
                    <div
                      class="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5"
                    >
                      <div>
                        <label
                          for="warehouse_description"
                          class="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                        >
                          Description
                        </label>
                      </div>
                      <div class="sm:col-span-2">
                        <textarea
                          id="warehouse_description"
                          name="warehouse_description"
                          v-model="create.description"
                          rows="3"
                          class="block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                    </div>

                    <!-- Warehouse address -->
                    <div
                      class="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5"
                    >
                      <div>
                        <label
                          for="warehouse_address_1"
                          class="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                        >
                          Address 1
                        </label>
                      </div>
                      <div class="sm:col-span-2">
                        <input
                          type="text"
                          name="warehouse_address_1"
                          id="warehouse_address_1"
                          v-model="create.addressLine1"
                          class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                    </div>

                    <!-- Warehouse address -->
                    <div
                      class="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5"
                    >
                      <div>
                        <label
                          for="warehouse_address_2"
                          class="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                        >
                          Address 2
                        </label>
                      </div>
                      <div class="sm:col-span-2">
                        <input
                          type="text"
                          name="warehouse_address_2"
                          id="warehouse_address_2"
                          v-model="create.addressLine2"
                          class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                    </div>

                    <!-- Warehouse city -->
                    <div
                      class="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5"
                    >
                      <div>
                        <label
                          for="warehouse_city"
                          class="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                        >
                          City
                        </label>
                      </div>
                      <div class="sm:col-span-2">
                        <input
                          type="text"
                          name="warehouse_city"
                          id="warehouse_city"
                          v-model="create.city"
                          class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                    </div>

                    <!-- Warehouse state -->
                    <div
                      class="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5"
                    >
                      <div>
                        <label
                          for="warehouse_state"
                          class="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                        >
                          State
                        </label>
                      </div>
                      <div class="sm:col-span-2">
                        <input
                          type="text"
                          name="warehouse_state"
                          id="warehouse_state"
                          v-model="create.state"
                          class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                    </div>

                    <!-- Warehouse zipCode -->
                    <div
                      class="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5"
                    >
                      <div>
                        <label
                          for="warehouse_zip"
                          class="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                        >
                          Zip
                        </label>
                      </div>
                      <div class="sm:col-span-2">
                        <input
                          type="text"
                          name="warehouse_zip"
                          id="warehouse_zip"
                          v-model="create.zipCode"
                          class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                    </div>

                    <!-- Warehouse country -->
                    <div
                      class="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5"
                    >
                      <div>
                        <label
                          for="warehouse_country"
                          class="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                        >
                          Country
                        </label>
                      </div>
                      <div class="sm:col-span-2">
                        <input
                          type="text"
                          name="warehouse_country"
                          id="warehouse_country"
                          v-model="create.country"
                          class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                    </div>

                    <!-- Warehouse totalShelvesCount -->
                    <div
                      class="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5"
                    >
                      <div>
                        <label
                          for="warehouse_totalShelvesCount"
                          class="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                        >
                          Number of Shelves
                        </label>
                      </div>
                      <div class="sm:col-span-2">
                        <input
                          type="number"
                          min="0"
                          name="warehouse_totalShelvesCount"
                          id="warehouse_totalShelvesCount"
                          v-model="create.totalShelvesCount"
                          class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                    </div>

                    <!-- Warehouse totalShelfBinsCount -->
                    <div
                      class="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5"
                    >
                      <div>
                        <label
                          for="warehouse_totalShelvesCount"
                          class="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                        >
                          Number of Shelf Bins
                        </label>
                      </div>
                      <div class="sm:col-span-2">
                        <input
                          type="number"
                          min="0"
                          name="warehouse_totalShelfBinsCount"
                          id="warehouse_totalShelfBinsCount"
                          v-model="create.totalShelfBinsCount"
                          class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Action buttons -->
                <div
                  class="flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6"
                >
                  <div class="flex justify-end space-x-3">
                    <button
                      type="button"
                      class="focus:outline-none rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-indigo-500"
                      @click="open = false"
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      @click="saveCreateForm"
                      class="focus:outline-none inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:ring-indigo-500"
                    >
                      Create
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>

  <div
    aria-live="assertive"
    class="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6"
  >
    <div class="flex w-full flex-col items-center space-y-4 sm:items-end">
      <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
      <transition
        enter-active-class="transform ease-out duration-300 transition"
        enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div
          v-if="showAlert"
          class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5"
        >
          <div class="p-4">
            <div class="flex items-start">
              <div class="flex-shrink-0">
                <CheckCircleIcon
                  class="h-6 w-6 text-green-400"
                  aria-hidden="true"
                />
              </div>
              <div class="ml-3 w-0 flex-1 pt-0.5">
                <p class="text-left text-sm font-medium text-gray-900">
                  Successfully saved!
                </p>
                <p class="mt-1 text-left text-sm text-gray-500">
                  Anyone with a link can now view this file.
                </p>
              </div>
              <div class="ml-4 flex flex-shrink-0">
                <button
                  @click="showAlert = false"
                  class="focus:outline-none inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-indigo-500"
                >
                  <span class="sr-only">Close</span>
                  <XIcon class="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { reactive, ref, onMounted } from "vue";
import {
  Menu,
  MenuButton,
  MenuItems,
  MenuItem,
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import {
  XIcon,
  TrashIcon,
  PencilIcon,
  SearchIcon,
  ChevronDownIcon,
  CheckCircleIcon,
  SortAscendingIcon,
} from "@heroicons/vue/outline";

import Pagination from "@/common/components/pagination";

import useWarehousesRepository from "@/composable/useWarehousesRepository";

import API from "@/api";

import { directive } from "vue-tippy";
import "tippy.js/animations/scale.css";

export default {
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    XIcon,
    Menu,
    MenuButton,
    MenuItems,
    MenuItem,
    Pagination,
    TrashIcon,
    PencilIcon,
    SearchIcon,
    SortAscendingIcon,
    ChevronDownIcon,
    CheckCircleIcon,
  },
  directives: {
    tippy: directive,
  },
  setup() {
    const open = ref(false);
    const showAlert = ref(false);
    const loading = ref(true);

    const { warehouses, getWarehouses, saveWarehouse } =
      useWarehousesRepository();

    const pagination = reactive({
      page: 1,
      pageSize: 10,
      totalResults: 0,
    });
    const create = reactive({
      name: "",
      description: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      zipCode: "",
      country: "",
      totalShelvesCount: 0,
      totalShelfBinsCount: 0,
    });

    const onChangePage = (page) => {
      pagination.page = page;
      getWarehouses(pagination.page, pagination.pageSize);
    };

    const openCreatePanel = () => {
      open.value = true;
    };

    const saveCreateForm = () => {
      // saveWarehouse(create);
      // open.value = false;
      loading.value = true;
      API.Products.Warehouses.save(create).then((result) => {
        open.value = false;
        console.log({ result });
        getWarehouses(pagination.page, pagination.pageSize);
        // warehouses.value = [];
        // warehouses.value = result.data.List;
        loading.value = false;
        showAlert.value = true;
      });
    };

    onMounted(() => {
      getWarehouses(pagination.page, pagination.pageSize);
      console.log({ warehouses });
    });

    return {
      open,
      create,
      loading,
      showAlert,
      pagination,
      warehouses,
      saveWarehouse,
      getWarehouses,
      onChangePage,
      openCreatePanel,
      saveCreateForm,
    };
  },
};
</script>
