import { ref } from "vue";

import API from "@/api";

export default function useWarehousesRepository() {
  const warehouses = ref([]);

  const getWarehouses = async (page, limit) => {
    warehouses.value = [];
    try {
      let result = await API.Products.Warehouses.get(page, limit);
      warehouses.value = result.data.List;
      console.log({ warehouses });
    } catch (error) {
      console.log(error);
    }
  };

  const saveWarehouse = async (data) => {
    try {
      let result = await API.Products.Warehouses.save(data);
      console.log(result);
      getWarehouses;
    } catch (error) {
      console.log(error);
    }
  };

  return {
    warehouses,
    saveWarehouse,
    getWarehouses,
  };
}
